const debug = require('debug')('mph:sdk:audio');
/*
CryptoJS v3.1.2
code.google.com/p/crypto-js
(c) 2009-2013 by Jeff Mott. All rights reserved.
code.google.com/p/crypto-js/wiki/License
*/
var CryptoJS = CryptoJS || (function (e, m) {
  var p = {}; var j = p.lib = {}; var l = function () {}; var f = j.Base = { extend: function (a) { l.prototype = this; var c = new l(); a && c.mixIn(a); c.hasOwnProperty('init') || (c.init = function () { c.$super.init.apply(this, arguments); }); c.init.prototype = c; c.$super = this; return c; }, create: function () { var a = this.extend(); a.init.apply(a, arguments); return a; }, init: function () {}, mixIn: function (a) { for (var c in a)a.hasOwnProperty(c) && (this[c] = a[c]); a.hasOwnProperty('toString') && (this.toString = a.toString); }, clone: function () { return this.init.prototype.extend(this); } };
  var n = j.WordArray = f.extend({
    init: function (a, c) { a = this.words = a || []; this.sigBytes = c != m ? c : 4 * a.length; },
    toString: function (a) { return (a || h).stringify(this); },
    concat: function (a) { var c = this.words; var q = a.words; var d = this.sigBytes; a = a.sigBytes; this.clamp(); if (d % 4) for (var b = 0; b < a; b++)c[d + b >>> 2] |= (q[b >>> 2] >>> 24 - 8 * (b % 4) & 255) << 24 - 8 * ((d + b) % 4); else if (q.length > 65535) for (b = 0; b < a; b += 4)c[d + b >>> 2] = q[b >>> 2]; else c.push.apply(c, q); this.sigBytes += a; return this; },
    clamp: function () {
      var a = this.words; var c = this.sigBytes; a[c >>> 2] &= 4294967295 <<
32 - 8 * (c % 4); a.length = e.ceil(c / 4);
    },
    clone: function () { var a = f.clone.call(this); a.words = this.words.slice(0); return a; },
    random: function (a) { for (var c = [], b = 0; b < a; b += 4)c.push(4294967296 * e.random() | 0); return new n.init(c, a); }
  }); var b = p.enc = {}; var h = b.Hex = {
    stringify: function (a) { var c = a.words; a = a.sigBytes; for (var b = [], d = 0; d < a; d++) { var f = c[d >>> 2] >>> 24 - 8 * (d % 4) & 255; b.push((f >>> 4).toString(16)); b.push((f & 15).toString(16)); } return b.join(''); },
    parse: function (a) {
      for (var c = a.length, b = [], d = 0; d < c; d += 2) {
        b[d >>> 3] |= parseInt(a.substr(d,
                                        2), 16) << 24 - 4 * (d % 8);
      } return new n.init(b, c / 2);
    }
  }; var g = b.Latin1 = { stringify: function (a) { var c = a.words; a = a.sigBytes; for (var b = [], d = 0; d < a; d++)b.push(String.fromCharCode(c[d >>> 2] >>> 24 - 8 * (d % 4) & 255)); return b.join(''); }, parse: function (a) { for (var c = a.length, b = [], d = 0; d < c; d++)b[d >>> 2] |= (a.charCodeAt(d) & 255) << 24 - 8 * (d % 4); return new n.init(b, c); } }; var r = b.Utf8 = { stringify: function (a) { try { return decodeURIComponent(escape(g.stringify(a))); } catch (c) { throw Error('Malformed UTF-8 data'); } }, parse: function (a) { return g.parse(unescape(encodeURIComponent(a))); } };
  var k = j.BufferedBlockAlgorithm = f.extend({
    reset: function () { this._data = new n.init(); this._nDataBytes = 0; },
    _append: function (a) { typeof a === 'string' && (a = r.parse(a)); this._data.concat(a); this._nDataBytes += a.sigBytes; },
    _process: function (a) { var c = this._data; var b = c.words; var d = c.sigBytes; var f = this.blockSize; var h = d / (4 * f); var h = a ? e.ceil(h) : e.max((h | 0) - this._minBufferSize, 0); a = h * f; d = e.min(4 * a, d); if (a) { for (var g = 0; g < a; g += f) this._doProcessBlock(b, g); g = b.splice(0, a); c.sigBytes -= d; } return new n.init(g, d); },
    clone: function () {
      var a = f.clone.call(this);
      a._data = this._data.clone(); return a;
    },
    _minBufferSize: 0
  }); j.Hasher = k.extend({
    cfg: f.extend(),
    init: function (a) { this.cfg = this.cfg.extend(a); this.reset(); },
    reset: function () { k.reset.call(this); this._doReset(); },
    update: function (a) { this._append(a); this._process(); return this; },
    finalize: function (a) { a && this._append(a); return this._doFinalize(); },
    blockSize: 16,
    _createHelper: function (a) { return function (c, b) { return (new a.init(b)).finalize(c); }; },
    _createHmacHelper: function (a) {
      return function (b, f) {
        return (new s.HMAC.init(a,
                                f)).finalize(b);
      };
    }
  }); var s = p.algo = {}; return p;
}(Math));
(function () {
  var e = CryptoJS; var m = e.lib; var p = m.WordArray; var j = m.Hasher; var l = []; var m = e.algo.SHA1 = j.extend({
    _doReset: function () { this._hash = new p.init([1732584193, 4023233417, 2562383102, 271733878, 3285377520]); },
    _doProcessBlock: function (f, n) {
      for (var b = this._hash.words, h = b[0], g = b[1], e = b[2], k = b[3], j = b[4], a = 0; a < 80; a++) {
        if (a < 16)l[a] = f[n + a] | 0; else { var c = l[a - 3] ^ l[a - 8] ^ l[a - 14] ^ l[a - 16]; l[a] = c << 1 | c >>> 31; }c = (h << 5 | h >>> 27) + j + l[a]; c = a < 20 ? c + ((g & e | ~g & k) + 1518500249) : a < 40 ? c + ((g ^ e ^ k) + 1859775393) : a < 60 ? c + ((g & e | g & k | e & k) - 1894007588) : c + ((g ^ e ^
k) - 899497514); j = k; k = e; e = g << 30 | g >>> 2; g = h; h = c;
      }b[0] = b[0] + h | 0; b[1] = b[1] + g | 0; b[2] = b[2] + e | 0; b[3] = b[3] + k | 0; b[4] = b[4] + j | 0;
    },
    _doFinalize: function () { var f = this._data; var e = f.words; var b = 8 * this._nDataBytes; var h = 8 * f.sigBytes; e[h >>> 5] |= 128 << 24 - h % 32; e[(h + 64 >>> 9 << 4) + 14] = Math.floor(b / 4294967296); e[(h + 64 >>> 9 << 4) + 15] = b; f.sigBytes = 4 * e.length; this._process(); return this._hash; },
    clone: function () { var e = j.clone.call(this); e._hash = this._hash.clone(); return e; }
  }); e.SHA1 = j._createHelper(m); e.HmacSHA1 = j._createHmacHelper(m);
})();

audioData = {};

if ((window.AudioContext || window.webkitAudioContext) === undefined) {
  audioData = 'Not supported';
} else {
  // Performs fingerprint as found in https://client.a.pxi.pub/PXmssU3ZQ0/main.min.js
  // Sum of buffer values
  function run_pxi_fp () {
    try {
      if (context = new (window.OfflineAudioContext || window.webkitOfflineAudioContext)(1, 44100, 44100), !context) {
        audioData.pxi_output = 0;
      }

      // Create oscillator
      var pxi_oscillator = context.createOscillator();
      pxi_oscillator.type = 'triangle';
      pxi_oscillator.frequency.value = 1e4;

      // Create and configure compressor
      var pxi_compressor = context.createDynamicsCompressor();
      pxi_compressor.threshold && (pxi_compressor.threshold.value = -50);
      pxi_compressor.knee && (pxi_compressor.knee.value = 40);
      pxi_compressor.ratio && (pxi_compressor.ratio.value = 12);
      pxi_compressor.reduction && (pxi_compressor.reduction.value = -20);
      pxi_compressor.attack && (pxi_compressor.attack.value = 0);
      pxi_compressor.release && (pxi_compressor.release.value = 0.25);

      // Connect nodes
      pxi_oscillator.connect(pxi_compressor);
      pxi_compressor.connect(context.destination);

      // Start audio processing
      pxi_oscillator.start(0);
      context.startRendering();
      context.oncomplete = function (evnt) {
        audioData.pxi_output = 0;
        var sha1 = CryptoJS.algo.SHA1.create();
        for (var i = 0; i < evnt.renderedBuffer.length; i++) {
          sha1.update(evnt.renderedBuffer.getChannelData(0)[i].toString());
        }
        var hash = sha1.finalize();
        audioData.pxi_full_buffer_hash = hash.toString(CryptoJS.enc.Hex);
        for (var i = 4500; i < 5e3; i++) {
          audioData.pxi_output += Math.abs(evnt.renderedBuffer.getChannelData(0)[i]);
        }
        pxi_compressor.disconnect();
      };
    } catch (u) {
      audioData.pxi_output = 0;
    }
  }

  // End PXI fingerprint

  // Performs fingerprint as found in some versions of http://metrics.nt.vc/metrics.js
  function a (a, b, c) {
    for (var d in b) {
      d === 'dopplerFactor' || d === 'speedOfSound' || d ===
        'currentTime' || typeof b[d] !== 'number' && typeof b[d] !== 'string' || (a[(c || '') + d] = b[d]);
    }
    return a;
  }

  function run_nt_vc_fp () {
    try {
      var nt_vc_context = window.AudioContext || window.webkitAudioContext;
      if (typeof nt_vc_context !== 'function') audioData.nt_vc_output = 'Not available';
      else {
        var f = new nt_vc_context();
        var d = f.createAnalyser();
        audioData.nt_vc_output = a({}, f, 'ac-');
        audioData.nt_vc_output = a(audioData.nt_vc_output, f.destination, 'ac-');
        audioData.nt_vc_output = a(audioData.nt_vc_output, f.listener, 'ac-');
        audioData.nt_vc_output = a(audioData.nt_vc_output, d, 'an-');
      }
    } catch (g) {
      audioData.nt_vc_output = 0;
    }
  }

  // Performs fingerprint as found in https://www.cdn-net.com/cc.js
  var cc_output = [];

  function run_cc_fp () {
    var audioCtx = new (window.AudioContext || window.webkitAudioContext)();
    var oscillator = audioCtx.createOscillator();
    var analyser = audioCtx.createAnalyser();
    var gain = audioCtx.createGain();
    var scriptProcessor = audioCtx.createScriptProcessor(4096, 1, 1);

    gain.gain.value = 0; // Disable volume
    oscillator.type = 'triangle'; // Set oscillator to output triangle wave
    oscillator.connect(analyser); // Connect oscillator output to analyser input
    analyser.connect(scriptProcessor); // Connect analyser output to scriptProcessor input
    scriptProcessor.connect(gain); // Connect scriptProcessor output to gain input
    gain.connect(audioCtx.destination); // Connect gain output to audiocontext destination

    scriptProcessor.onaudioprocess = function () {
      const bins = new Float32Array(analyser.frequencyBinCount);
      analyser.getFloatFrequencyData(bins);
      for (var i = 0; i < bins.length; i = i + 1) {
        cc_output.push(bins[i]);
      }
      analyser.disconnect();
      scriptProcessor.disconnect();
      gain.disconnect();
      audioData.cc_output = cc_output.slice(0, 30);
    };

    oscillator.start(0);
  }

  // Performs a hybrid of cc/pxi methods found above
  var hybrid_output = [];

  function run_hybrid_fp () {
    var audioCtx = new (window.AudioContext || window.webkitAudioContext)();
    var oscillator = audioCtx.createOscillator();
    var analyser = audioCtx.createAnalyser();
    var gain = audioCtx.createGain();
    var scriptProcessor = audioCtx.createScriptProcessor(4096, 1, 1);

    // Create and configure compressor
    var compressor = audioCtx.createDynamicsCompressor();
    compressor.threshold && (compressor.threshold.value = -50);
    compressor.knee && (compressor.knee.value = 40);
    compressor.ratio && (compressor.ratio.value = 12);
    compressor.reduction && (compressor.reduction.value = -20);
    compressor.attack && (compressor.attack.value = 0);
    compressor.release && (compressor.release.value = 0.25);

    gain.gain.value = 0; // Disable volume
    oscillator.type = 'triangle'; // Set oscillator to output triangle wave
    oscillator.connect(compressor); // Connect oscillator output to dynamic compressor
    compressor.connect(analyser); // Connect compressor to analyser
    analyser.connect(scriptProcessor); // Connect analyser output to scriptProcessor input
    scriptProcessor.connect(gain); // Connect scriptProcessor output to gain input
    gain.connect(audioCtx.destination); // Connect gain output to audiocontext destination

    scriptProcessor.onaudioprocess = function () {
      const bins = new Float32Array(analyser.frequencyBinCount);
      analyser.getFloatFrequencyData(bins);
      for (var i = 0; i < bins.length; i = i + 1) {
        hybrid_output.push(bins[i]);
      }
      analyser.disconnect();
      scriptProcessor.disconnect();
      gain.disconnect();

      audioData.hybrid_output = hybrid_output.slice(0, 30);
    };

    oscillator.start(0);
  }

  try {
    run_pxi_fp();
    run_nt_vc_fp();
    run_cc_fp();
    run_hybrid_fp();
  } catch(ex) {
    debug("Unable to run FP functions on audio");
    debug(ex);
  }
}
