const debug = require('debug')('mph:sdk:fp');
platform = window.navigator.platform;
cookieEnabled = window.navigator.cookieEnabled ? 'yes' : 'no';

// ** Check doNotTrack value from navigator
doNotTrack = '';
if (window.navigator.doNotTrack != null && window.navigator.doNotTrack != 'unspecified') {
  if (window.navigator.doNotTrack == '1' || window.navigator.doNotTrack == 'yes') {
    doNotTrack = 'yes';
  } else {
    doNotTrack = 'no';
  }
} else {
  doNotTrack = 'NC';
}

// ** Get hardwareConcurrency value from navigator
hardwareConcurrency = window.navigator.hardwareConcurrency;

// ** Get deviceMemory value from navigator
deviceMemory = window.navigator.deviceMemory;

// ** Get maxTouchPoints value from navigator
maxTouchPoints = 0
if (window.navigator.maxTouchPoints !== undefined) {
      maxTouchPoints = window.navigator.maxTouchPoints;
}

// ** Get TouchEvent value from navigator
touchEvent = false;
try {
  document.createEvent('TouchEvent')
  touchEvent = true;
} catch {
  touchEvent = false;
}

// ** Get touchStart value from window
touchStart = 'ontouchstart' in window;

// ** Retrieve timezone value
timezone = new Date().getTimezoneOffset();

// ** Get screen resolution
resolution = window.screen.width + 'x' + window.screen.height + 'x' + window.screen.colorDepth;

// ** Get plugins value from navigator
// Enumeration of navigator.plugins or use of Plugin detect
plugins = '';
const np = window.navigator.plugins;
const plist = new Array();
for (var i = 0; i < np.length; i++) {
  plist[i] = np[i].name + '; ';
  plist[i] += np[i].description + '; ';
  plist[i] += np[i].filename;
  plist[i] += '. ';
}
plist.sort();
for (i = 0; i < np.length; i++) {
  plugins += 'Plugin ' + i + ': ' + plist[i];
}

// ** Get ieUserData
try {
  ieUserData = '';
  oPersistDiv.setAttribute('testStorage', 'value remembered');
  oPersistDiv.save('oXMLStore');
  oPersistDiv.setAttribute('testStorage', 'overwritten!');
  oPersistDiv.load('oXMLStore');
  if ((oPersistDiv.getAttribute('testStorage')) == 'value remembered') {
    ieUserData = 'yes';
  } else {
    ieUserData = 'no';
  }
} catch (ex) {
  //debug(ex); // dont need to log that stuff, we do not support IE
  ieUserData = 'no';
}

// ** Get localStorage data
try {
  localStorage.fp = 'test';
  sessionStorage.fp = 'test';
} catch (ex) {
  debug(ex); // FIXME no chance for this log to be printed in browser console if localStorage is disabled (WebView for example)
}

try {
  domLocalStorage = '';
  if (localStorage.fp == 'test') {
    domLocalStorage = 'yes';
  } else {
    domLocalStorage = 'no';
  }
} catch (ex) {
  debug(ex); // FIXME no chance for this log to be printed in browser console if localStorage is disabled (WebView for example)
  domLocalStorage = 'no';
}

try {
  domSessionStorage = '';
  if (sessionStorage.fp == 'test') {
    domSessionStorage = 'yes';
  } else {
    domSessionStorage = 'no';
  }
} catch (ex) {
  debug(ex);
  domSessionStorage = 'no';
}
