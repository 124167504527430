const { SDK_LOG_RETRIEVAL_ENABLED } = require('./websdk-api-components/constants');
const debugHandler = require('./websdk-api-components/debugHandler');
function initDebugModule(nameSpace) {
    const _debug = require('debug');
    const logOriginal = _debug.log;
    _debug.log = (...args) => {
        logOriginal(...args);
        debugHandler.debug(...args);
    };
    return _debug(nameSpace);
}

if (SDK_LOG_RETRIEVAL_ENABLED) {
    initDebugModule('mph:app:front');
}

const rtcApi = require('./websdk-api-components/rtc-api');
module.exports = rtcApi;
