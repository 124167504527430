const debug = require('debug')('mph:sdk:canvas');
const CANVAS_UNSTABLE = 'unstable';
try {
  var canvas = document.createElement('canvas');
  canvas.height = 60;
  canvas.width = 400;
  var canvasContext = canvas.getContext('2d');
  canvas.style.display = 'inline';
  canvasContext.textBaseline = 'alphabetic';
  canvasContext.fillStyle = '#f60';
  canvasContext.fillRect(125, 1, 62, 20);
  canvasContext.fillStyle = '#069';
  canvasContext.font = '11pt no-real-font-123';
  canvasContext.fillText('Cwm fjordbank glyphs vext quiz, \ud83d\ude03', 2, 15);
  canvasContext.fillStyle = 'rgba(102, 204, 0, 0.7)';
  canvasContext.font = '18pt Arial';
  canvasContext.fillText('Cwm fjordbank glyphs vext quiz, \ud83d\ude03', 4, 45);

  var canvasData1 = canvas.toDataURL();
  var canvasData2 = canvas.toDataURL();

  // Some browsers as samsung browser add a noise to the canvas
  // In this case, canvas is excluded from the fingerprint
  if (canvasData1 !== canvasData2) {
    canvasData = CANVAS_UNSTABLE;
  } else {
    canvasData = canvasData1;
  }

} catch (e) {
  debug(e);
  canvasData = 'Not supported';
}
